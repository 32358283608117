import React, { Component } from "react";
import { Card, Carousel, Col, Container, Row } from "react-bootstrap";
import phone from "../../images/c3-phone1.png";
import back from "../../images/c3-back.png";

class C3 extends Component {
  anim() {
    if (window !== undefined) {
      const simpleParallax = require("simple-parallax-js");
      var image = document.getElementsByClassName("sp-thumb");
      new simpleParallax(image, {
        orientation: "left",
        overflow: true,
        maxTransition: 60,
      });
    }
  }
  componentDidMount() {
    this.anim();
  }

  componentDidUpdate() {
    this.anim();
  }

  render() {
    const appBenefits = this.props.data;
    if (!appBenefits) return <></>;

    let carousel = [];
    appBenefits?.carousel.map((_, index) => {
      if (index % 2 == 0) {
        carousel.push(appBenefits.carousel.slice(index, index + 2));
      }
    });

    return (
      <React.Fragment>
        <Container
          fluid
          className="c3 c-pad"
          style={{ backgroundImage: `url(${back})` }}
        >
          <Row>
            <Col className="c3-col-1 col-md-8 col-sm-12 d-none d-md-block">
              <div className="c-h1 mb-4">{appBenefits.header}</div>
              <div className="c-h2 mb-4">{appBenefits.subHeader}</div>
              <div>
                <Carousel className="carousel1" indicators={true}>
                  {carousel.map((item, index) => {
                    return (
                      <Carousel.Item className="" key={index}>
                        <div className="d-flex">
                          {item.map((carousel_item, index) => {
                            return (
                              <Col key={index}>
                                <Card className="text-center ms-0 my-0 m-3 p-3 c-shadow h-100">
                                  <img
                                    alt=""
                                    className="mx-auto mb-4"
                                    src={
                                      this.props.assets[carousel_item.image].url
                                    }
                                    height="56px"
                                    width="auto"
                                  />
                                  <div className="carousel-h1 mb-3">
                                    {carousel_item.title}
                                  </div>
                                  <div className="c2-h2">
                                    {carousel_item.description}
                                  </div>
                                </Card>
                              </Col>
                            );
                          })}
                        </div>
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
              </div>
            </Col>
            <Col className="c3-col-1 col-md-8 col-sm-12 d-md-none">
              <div className="c-h1 mb-4">{appBenefits.header}</div>
              <div className="c-h2 mb-4">{appBenefits.subHeader} </div>
              <div>
                <Carousel className="carousel1" indicators={true}>
                  {appBenefits.carousel.map((carousel_item, index) => {
                    return (
                      <Carousel.Item className="" key={index}>
                        <div className="d-flex">
                          <Card className="text-center my-0 m-3 p-3 c-shadow">
                            <img
                              alt=""
                              className="mx-auto mb-4"
                              src={this.props.assets[carousel_item.image].url}
                              height="56px"
                              width="auto"
                            />
                            <div className="carousel-h1 mb-3">
                              {carousel_item.title}
                            </div>
                            <div className="c2-h2">
                              {carousel_item.description}
                            </div>
                          </Card>
                        </div>
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
              </div>
            </Col>

            <Col
              className="c3-col-2 d-none d-md-flex flex-column-reverse"
              md="4"
            >
              <img
                alt=""
                className="sp-thumb"
                src={this.props.assets["junio_card_in_emulator"]?.url}
                height="520px"
                width="auto"
              />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default C3;
